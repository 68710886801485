export const baseUrl = "https://api.naanmudhalvan.tn.gov.in/";
export const baseUrlNoEndSlash = "https://api.naanmudhalvan.tn.gov.in";

// export const baseUrl = "https://ee11-117-247-183-95.in.ngrok.io/";
// export const baseUrlNoEndSlash = "https://ee11-117-247-183-95.in.ngrok.io/";

// export const baseUrl = "http://192.168.0.125:8000/";
// export const baseUrlNoEndSlash = "http://192.168.0.125:8000";

// export const baseUrl = "http://10.236.220.196/app/";
// export const baseUrlNoEndSlash = "http://10.236.220.196/app";

//export const baseUrl = "http://192.168.0.143:8000/";
//export const baseUrlNoEndSlash = "http://192.168.0.143:8000";

// export const baseUrl = "http://10.236.220.196:8000/";
// export const baseUrlNoEndSlash = "http://10.236.220.196:8000";

// export const baseUrl = "http://135.125.204.14/";
// export const baseUrlNoEndSlash = "http://135.125.204.14/";
// export const baseUrl = "http://127.0.0.1:8000/";
// export const baseUrlNoEndSlash = "http://127.0.0.1:8000";

