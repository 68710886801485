import axios from "@/http/http";
import {Module, ActionTree} from "vuex";
import { newAxios } from "../student/studentPlacements";

const actions: ActionTree<any, any> = {
    getReportCollegesList({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {

            commit("SET_LOADING", true);
            try {
                const response = await axios.get(`/api/v1/college/colleges_list`, {params: data});

                commit("setCollegesData", response.data);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
    getReportPlacementsOverview({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {

            commit("SET_LOADING", true);
            try {
                const response = await axios.get(`/api/v1/placements/placement_overview`, {params: data});

                commit("setPlacementsOverviewData", response.data);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },

    getReportPlacementsDistrictOverview({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {

            commit("SET_LOADING", true);
            try {
                const response = await axios.get(`/api/v1/placements/district_overview`, {params: data});

                commit("setPlacementsDistrictOverviewData", response.data);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },

    getPlacementReportCSV({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor

        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {

                const response = await newAxios.post("/reports/api/placements/getPlacementStatusCSV.php", formData);

                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    getAllEventRegistrations({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor

        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {

                const response = await newAxios.post("/reports/api/data/downloadEventDetails.php", formData);

                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    getPlacementReportSectorwiseCSV({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor

        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {

                const response = await newAxios.post("/reports/api/placements/getPlacementStatusCSV1.php", formData);

                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    getPlacementReportAbstractCSV({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor

        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {

                const response = await newAxios.post("/reports/api/placements/getSectorwiseCount.php", formData);

                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    getAspirationStudentDetails({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor

        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {

                const response = await newAxios.post("/reports/api/placements/aspiration_detailed_report.php", formData);

                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    }
};
const adminReportCollege: Module<any, any> = {
    state: () => ({
        reportCollegesList: [],
        placementsOverviewData: {},
        placementsDistrictOverviewData: {},
    }),
    mutations: {
        resetData(state) {
            state.reportCollegesList = [];
        },
        setCollegesData(state, data) {
            state.reportCollegesList = data;
        },
        setPlacementsOverviewData(state, data) {
            state.placementsOverviewData = data;
        },
        setPlacementsDistrictOverviewData(state, data) {
            state.placementsDistrictOverviewData = data;
        }
    },
    actions,
    getters: {
        reportCollegesList(state) {
            return state.reportCollegesList;
        },
        placementsOverviewData(state) {
            return state.placementsOverviewData;
        },
        placementsDistrictOverviewData(state) {
            return state.placementsDistrictOverviewData;
        }
    },
};

export default adminReportCollege;
