import axios from "@/http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  getIndustryJobs({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("resetData");
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/industry/job/list/`, { params: data });
        commit("setIndustryJobsData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  addNewIndustryJob({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/industry/job/add/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getIndustryInternships({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("resetData");
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/industry/internship/list/`, { params: data });
        commit("setIndustryInternshipsData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  addNewIndustryInternship({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/industry/internship/add/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getIndustryMentorship({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/industry/mentorship/list/`, { params: data });
        commit("setIndustryMentorshipData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  addNewIndustryMentorship({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/industry/mentorship/add/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getIndustryTrainings({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/industry/training/list/`, { params: data });
        commit("setIndustryTrainingData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  addNewIndustryTraining({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/industry/training/add/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
};
const industriesStore: Module<any, any> = {
  state: () => ({
    iJobs: [],
    jTotalPages: 0,
    jPage: 0,
    iInternships: [],
    iTotalPages: 0,
    iPage: 0,
    iMentorship: [],
    mTotalPages: 0,
    mPage: 0,
    iTrainings: [],
    tTotalPages: 0,
    tPage: 0,
  }),
  mutations: {
    resetData(state) {
      // state.iJobs = [];
      // state.jTotalPages = 0;
      // state.jPage = 0;
    },
    setIndustryJobsData(state, data) {
        state.iJobs = data.internships;
        state.jTotalPages = Math.ceil(data.filters.total_count / data.filters.limit);
        state.jPage = data.filters.page;
    },
    setIndustryInternshipsData(state, data) {
        state.iInternships = data.internships;
        state.iTotalPages = Math.ceil(data.filters.total_count / data.filters.limit);
        state.iPage = data.filters.page;
    },
    setIndustryMentorshipData(state, data) {
        state.iMentorship = data.mentors;
        state.mTotalPages = Math.ceil(data.filters.total_count / data.filters.limit);
        state.mPage = data.filters.page;
    },
    setIndustryTrainingData(state, data) {
        state.iTrainings = data.internships;
        state.tTotalPages = Math.ceil(data.filters.total_count / data.filters.limit);
        state.tPage = data.filters.page;
    },
  },
  actions,
  getters: {
    iJobs(state) {
        return state.iJobs;
    },
    iJTotalPages(state) {
      return state.jTotalPages;
    },
    ijPage(state) {
      return state.jPage;
    },
    iInternships(state) {
        return state.iInternships;
    },
    iITotalPages(state) {
      return state.iTotalPages;
    },
    iIPage(state) {
      return state.iPage;
    },
    iMentorship(state) {
        return state.iMentorship;
    },
    iMTotalPages(state) {
      return state.mTotalPages;
    },
    iMPage(state) {
      return state.mPage;
    },
    iTrainings(state) {
        return state.iTrainings;
    },
    iTTotalPages(state) {
      return state.tTotalPages;
    },
    iTPage(state) {
      return state.tTotalPages;
    },
  },
};

export default industriesStore;
