import axios from "@/http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  getReportMCOverview({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/reports/skill-offerings/overview`, { params: data });

        commit("setMCData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getReportMCStudentsList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/reports/skill-offerings/detail`, { params: data });

        commit("setMCStudentsData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
};
const adminStore: Module<any, any> = {
  state: () => ({
    reportMCList: [],
    reportMCCourse: {},
    reportMCStudentsList: [],
    rMCTotalPages: 0,
    rMCLimit: 20,
    rMCPage: 0,
  }),
  mutations: {
    resetData(state) {
      state.reportMCList = [];
      state.reportMCStudentsList = [];
      state.rMCTotalPages = 0;
      state.rMCLimit = 20;
      state.rMCPage = 0;
    },
    setMCData(state, data) {
      state.reportMCList = data.reports;
    },
    setMCStudentsData(state, data) {
        state.reportMCCourse = data
        state.reportMCStudentsList = data.enrolled_list;
        state.rMCTotalPages = Math.ceil(data.filters.total_count / data.filters.limit);
        state.rMCLimit = data.filters.limit;
        state.rMCPage = data.filters.page;
    },
  },
  actions,
  getters: {
    reportMCList(state) {
        return state.reportMCList;
    },
    reportMCCourse(state) {
        return state.reportMCCourse;
    },
    reportMCStudentsList(state) {
        return state.reportMCStudentsList;
    },
    rMCTotalPages(state) {
        return state.rMCTotalPages;
    },
    rMCLimit(state) {
        return state.rMCLimit;
    },
    rMCPage(state) {
        return state.rMCPage;
    }
  },
};

export default adminStore;
