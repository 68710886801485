import axios from "@/http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  getMCList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/skillofferings/nm/mandatory/courses/list/`, { params: data });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  
  getMCListFilter({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/skillofferings/nm/mandatory/courses/filter/`, { params: data });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getCertListFilter({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/skillofferings/nm/certificate/filter/`, { params: data });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getCertGenerate({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/skillofferings/nm/certificate/generate-admin/`, { params: data });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getMCAllEnrollments({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/skillofferings/nm/mandatory/courses/enrollments/`, { params: data });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getCertCountData({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/skillofferings/nm/certificate/data/`, { params: data });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getMCAllAssessments({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/skillofferings/nm/mandatory/courses/assessments/`, { params: data });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getAllEnrolledListDownload({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/skillofferings/nm/mandatory/courses/enrollments_download/`, { params: data });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getAllAssessmentListDownload({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/skillofferings/nm/mandatory/courses/assessments_download/`, { params: data });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },



  getSubAdminCollegeStats({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/reports/subadmin/colleges/`,  { params: data });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getSubAdminDistrictStats({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/reports/subadmin/districts/`, { params: data });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getSubAdminDashboardStats({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/reports/subadmin/stats/`, { params: data });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getGenderCount({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/reports/subadmin/gender_count/`);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getAdminDashboardStats({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/reports/launch/stats/`);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  sendCollegeInvitations({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/college/registration-invites/`, formData);

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  reSendCollegeInvitations({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      // const formData = new FormData();
      //
      // Object.keys(data).forEach((key: string) => {
      //   formData.append(key, data[key]);
      // });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/college/registration/resend_college_invites/`, data);

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getCollegesList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("resetData");
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/college/list/`, { params: data });
        commit("setCollegeListData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getCollegeStats({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("resetData");
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/college/colleges-stats/`, { params: data });
        // commit("setCollegeListData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  
  getCollegeDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/college/details/${data.collegeId}`);
        commit("setCollegeData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  updateCollegeDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data.data).forEach((key: string) => {
        formData.append(key, data.data[key]);
      });
      commit("SET_LOADING", true);
      try {
        if(data.collegeId == 0) {
          const response = await axios.post(`/api/v1/college/details/${data.collegeId}/`, formData);
          resolve(response.data);
        } else {
          const response = await axios.patch(`/api/v1/college/details/${data.collegeId}/`, formData);
          // commit("setCollegeData", response.data);
          resolve(response.data);
        }
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  updateMCDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data.data).forEach((key: string) => {
        formData.append(key, data.data[key]);
      });
      commit("SET_LOADING", true);
      try {
        if(data.mc_ID == 0) {
          const response = await axios.post(`/api/v1/skillofferings/nm/mandatory/course/${data.mc_ID}/`, formData);
          resolve(response.data);
        } else {
          console.log(data.mc_ID)
          const response = await axios.patch(`/api/v1/skillofferings/nm/mandatory/course/${data.mc_ID}/`, formData);
          // commit("setCollegeData", response.data);
          resolve(response.data);
        }
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getAdminCoursesList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("resetData");
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/lms/courses/`, { params: data });
        commit("setAdminCoursesListData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  adminApproveRejectCourse({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        if(key != "course_id") {
          formData.append(key, data[key]);
        }
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/lms/course/update/${data.course_id}/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getPlacementOverview({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`api/v1/placements/vm_placement_details/`, { params: data });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getAllUsers({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`api/v1/users/list/`, { params: data });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getAllRoles({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`api/v1/data/account-role/`, { params: data });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  updateUserDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data.data).forEach((key: string) => {
        formData.append(key, data.data[key]);
      });
      commit("SET_LOADING", true);
      try {
        if(data.user_id == 0) {
          const response = await axios.post(`/api/v1/user/${data.user_id}/`, formData);
          resolve(response.data);
        } else {
          const response = await axios.patch(`/api/v1/user/${data.user_id}/`, formData);
          // commit("setCollegeData", response.data);
          resolve(response.data);
        }
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  sendInviteUser({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/user/send_invite/${data.user_id}/`, formData);

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },



};
const adminStore: Module<any, any> = {
  state: () => ({
    college: {},
    colleges: [],
    coursesList: [],
    studentCount: [],
    totalPages: 0,
    collegesCount: 0,
    limit: 20,
    page: 0,
  }),
  mutations: {
    resetData(state) {
      state.colleges = [];
      state.studentCount = [];
      state.collegesCount = 0
      state.verifiedCollegesCount = 0
      state.coursesPage = 0
      state.totalPages = 0;
      state.limit = 20;
      state.page = 0;
    },
    setCollegeData(state, data) {
      state.college = data;
    },
    setCollegeListData(state, data) {
        state.colleges = data.colleges_list;
        state.studentCount = data.student_count;
        state.collegesCount = data.total_count
        state.verifiedCollegesCount = data.colleges_verified_count
        state.totalPages = Math.ceil(data.total_count / data.limit);
        state.limit = data.limit;
        state.page = data.page;
    },
    setAdminCoursesListData(state, data) {
        state.coursesList = data.courses_list;
        // state.coursesCount = data.total_count
        state.totalPages = Math.ceil(data.total_count / data.limit);
        state.limit = data.limit;
        state.coursesPage = data.page;
    },
  },
  actions,
  getters: {
    adminCollegeList(state) {
        return state.colleges;
    },
    adminCollegeCount(state) {
        return state.collegesCount;
    },
    districtStudentCount(state){
      return state.studentCount;
    },
    adminVerifiedCollegesCount(state) {
        return state.verifiedCollegesCount;
    },
    universityStudentCount(state){
      return state.studentCount;
    },
    adminCoursesPage(state) {
        return state.coursesPage;
    },
    adminCoursesList(state) {
        return state.coursesList;
    },
    collegesTotalPages(state) {
      return state.totalPages;
    },
    collegesPage(state) {
      return state.page;
    },
    adminCollege(state) {
        return state.college;
    }
  },
};

export default adminStore;