import axios from "@/http/http";
import {Module, ActionTree} from "vuex";

const actions: ActionTree<any, any> = {
    getStudentCourseDetails({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {

            commit("SET_LOADING", true);
            try {
                const response = await axios.get(`/api/v1/lms/course/details/${data.courseId}/`);
                commit("setStudentCourseData", response.data);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
    getStudentCoursesList({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {

            commit("studentCoursesResetData");
            commit("SET_LOADING", true);
            try {
                const response = await axios.get(`/api/v1/lms/courses/`, {params: data});
                commit("setStudentCoursesListData", response.data);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
    getStudentSubscribedCoursesList({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {

            commit("studentCoursesResetData");
            commit("SET_LOADING", true);
            try {
                const response = await axios.get(`/api/v1/lms/courses/subscribe/`, {params: data});
                commit("setStudentCoursesListData", response.data);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
    studentSubscribeCourse({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });

            commit("SET_LOADING", true);
            try {
                const response = await axios.post(`/api/v1/lms/course/subscribe/`, formData);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
    studentWatchCourse({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });

            // commit("SET_LOADING", true);
            try {
                const response = await axios.post(`/api/v1/lms/course/subscribed/watch-url/`, formData);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            // commit("SET_LOADING", false);
        });
    },
    studentCourseReview({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });

            // commit("SET_LOADING", true);
            try {
                const response = await axios.post(`/api/v1/skillofferings/feed_back/`, formData);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            // commit("SET_LOADING", false);
        });
    },
};
const studentCoursesOldStore: Module<any, any> = {
    state: () => ({
        studentCourse: {},
        coursesList: [],
        totalPages: 0,
        coursesCount: 0,
        limit: 20,
        page: 0,
    }),
    mutations: {
        setStudentCourseData(state, data) {
            state.studentCourse = data;
        },
        studentCoursesResetData(state) {
            state.coursesList = [];
            state.coursesCount = 0
            state.totalPages = 0;
            state.limit = 20;
            state.page = 0;
        },
        setStudentCoursesListData(state, data) {
            state.coursesList = data.courses_list;
            state.collegesCount = data.total_count
            state.totalPages = Math.ceil(data.total_count / data.limit);
            state.limit = data.limit;
            state.page = data.page;
        },
    },
    actions,
    getters: {
        studentCoursesList(state) {
            return state.coursesList;
        },
        studentCoursesTotalPages(state) {
            return state.totalPages;
        },
        studentCoursesPage(state) {
            return state.page;
        },
        studentCourse(state) {
            return state.studentCourse;
        }
    },
};

export default studentCoursesOldStore;
