import axios from "@/http/http";
import {Module, ActionTree} from "vuex";

const actions: ActionTree<any, any> = {
    async fetchFSPartners({commit}) {
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            try {
                const response = await axios.get('/api/v1/kp/kps/?only_fs=true',)
                commit('setFSPartners', response.data);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
    async fetchFSPartnerDetails({commit}, data) {
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            try {
                const response = await axios.get('/api/v1/fs/courses', { params: data });
                commit('setFSPartnerDetails', response.data);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
    async enrollFSCourseStudent({commit}, data) {
        return new Promise(async (resolve, reject) => {

            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });

            commit("SET_LOADING", true);
            try {
                const response = await axios.post('/api/v1/fs/enrollment/', formData)
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
    async fetchEnrolledFSCourses({commit}) {
        return new Promise(async (resolve, reject) => {

            commit("SET_LOADING", true);
            try {
                const response = await axios.get('/api/v1/fs/enrollments/')
                commit('setFSStudentCourse', response.data.data);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
    async fetchFSProgress({commit}, data) {
        return new Promise(async (resolve, reject) => {

            commit("SET_LOADING", true);
            try {
                const response = await axios.get('/api/v1/fs/progress/', { params: data })
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
    // async enrollGetStudent({commit}, data) {
    //     return new Promise(async (resolve, reject) => {
    //
    //         const formData = new FormData();
    //
    //         Object.keys(data).forEach((key: string) => {
    //             formData.append(key, data[key]);
    //         });
    //
    //         commit("SET_LOADING", true);
    //         try {
    //             const response = await axios.get('/api/v1/skillofferings/skill_offering_enrollment/', { params: data })
    //             resolve(response.data);
    //         } catch (err: any) {
    //             try {
    //                 reject(err.response.data.message);
    //             } catch (e) {
    //                 reject(err.message);
    //             }
    //         }
    //         commit("SET_LOADING", false);
    //     });
    // },
};
const studentFSStore: Module<any, any> = {
    state: () => ({
        fsPartners: [],
        fsPartnerDetails: {},
        fsStudentCourses: [],
    }),
    mutations: {
        setFSPartners(state, data: any) {
            state.fsPartners = data;
        },
        setFSPartnerDetails(state, data: any) {
            state.fsPartnerDetails = data;
        },
        setFSStudentCourse(state, data: any) {
            state.fsStudentCourses = data;
        }
    },
    actions,
    getters: {
        fsPartners(state) {
            return state.fsPartners;
        },
        fsPartnerDetails(state) {
            return state.fsPartnerDetails;
        },
        fsStudentCourses(state) {
            return state.fsStudentCourses;
        }
    },
};

export default studentFSStore;
