import axios from "@/http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  getIndustriesList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("resetData");
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/industry/list/`, { params: data });
        commit("setIndustriesData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  approveRejectIndustry({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/industry/update/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getTrainingPartners({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("resetData");
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/kp/list/`, { params: data });
        // commit("setIndustriesData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  approveRejectTpLogo({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.patch(`/api/v1/kp/list/`,formData);
        // commit("setIndustriesData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
};
const adminIndustriesStore: Module<any, any> = {
  state: () => ({
    industries: [],
    totalPages: 0,
    limit: 20,
    page: 0,
  }),
  mutations: {
    resetData(state) {
      state.industries = [];
      state.totalPages = 0;
      state.limit = 20;
      state.page = 0;
    },
    setIndustriesData(state, data) {
        state.industries = data.industries;
        state.totalPages = Math.ceil(data.filters.total_count / data.filters.limit);
        state.limit = data.filters.limit;
        state.page = data.filters.page;
    },
  },
  actions,
  getters: {
    adminIndustriesList(state) {
        return state.industries;
    },
    industriesTotalPages(state) {
      return state.totalPages;
    },
    industriesPage(state) {
      return state.page;
    },
  },
};

export default adminIndustriesStore;
