import axios from "@/http/http";
import { Module, ActionTree } from "vuex";
import adminIndustriesStore from "@/store/admin/adminIndustriesStore";

const actions: ActionTree<any, any> = {
  getAdminCourseraAssessmentsHistory({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("resetData");
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/skillofferings/coursera/list/`, { params: data });
        commit("setCourseraAHistoryListData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  uploadAdminCourseraAssessmentFile({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/skillofferings/coursera/upload/`, formData);
        commit("setEOIData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getAdminMicrosoftAssessmentsHistory({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("resetData");
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/skillofferings/microsoft/list/`, { params: data });
        commit("setCourseraAHistoryListData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  uploadAdminMicrosoftAssessmentFile({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/skillofferings/microsoft/upload/`, formData);
        commit("setEOIData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getAdminInfosysAssessmentsHistory({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("resetData");
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/skillofferings/infosys/list/`, { params: data });
        commit("setCourseraAHistoryListData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  uploadAdminInfosysAssessmentFile({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/skillofferings/infosys/upload/`, formData);
        commit("setEOIData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
};
const assessmentsStore: Module<any, any> = {
  state: () => ({
    courseraAHistory: [],
    totalPages: 0,
    limit: 20,
    page: 0,
  }),
  mutations: {
    resetData(state) {
      state.courseraAHistory = [];
      state.totalPages = 0;
      state.limit = 20;
      state.page = 0;
    },
    setCourseraAHistoryListData(state, data) {
      state.courseraAHistory = data.upload_records;
      state.totalPages = Math.ceil(data.filters.total_count / data.filters.limit);
      state.limit = data.filters.limit;
      state.page = data.filters.page;
    },
  },
  actions,
  getters: {
    adminCourseraAHistory(state) {
      return state.courseraAHistory;
    },
    cAHTotalPages(state) {
      return state.totalPages;
    },
    cAHPage(state) {
      return state.page;
    },
  },
};

export default assessmentsStore;
