import axios from "@/http/http";
import axios1 from 'axios';
import {Module, ActionTree} from "vuex";
import eoiAuth from "@/http/eoiAuth";

export const newAxios = axios1.create({
    baseURL: 'https://naanmudhalvan.tn.gov.in/'
});

const actions: ActionTree<any, any> = {

    fetchPlacementStudentsList({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {

            commit("SET_LOADING", true);
            try {
                const response = await axios.get("/api/v1/placements/list/", { params: data });
                commit("setPlacementStudentsData", response.data);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    console.error(e);
                    reject(err.message);
                }
            }
        });
    },

    fetchStudentPlacementStatus({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {

            commit("SET_LOADING", true);
            try {
                const response = await axios.get("/api/v1/placements/details/", { params: data });
                commit("setPlacementData", response.data);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    console.error(e);
                    reject(err.message);
                }
            }
        });
    },

    getPlacementCompanies({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {

            commit("SET_LOADING", true);
            try {
                const response = await axios.get("/api/v1/placements/companies/", { params: data });
                commit("setPlacementCompanyData", response.data);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    console.error(e);
                    reject(err.message);
                }
            }
        });
    },

    updateCompanyList({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            
            const formData = new FormData();
            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });

            commit("SET_LOADING", true);
            try {
                const response = await axios.post("/api/v1/placements/companies/", formData);
                commit("setPlacementCompanyData", response.data);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    console.error(e);
                    reject(err.message);
                }
            }
        });
    },

    updateEditPlacementStatus({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });

            commit("SET_LOADING", true);
            try {
                const response = await axios.post("/api/v1/placements/details/", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    console.error(e);
                    reject(err.message);
                }
            }
        });
    },

    fetchAllPlacementData({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                const response = await newAxios.post("/reports/api/courses/getPlacementDetails.php", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },


    addSelectedCourse({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                const response = await newAxios.post("/reports/api/courses/insertCourseSelection.php", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    updateSelectedCourse({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                const response = await newAxios.post("/reports/api/courses/updateSelectedCourse.php", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    fetchSelectedCourses({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                const response = await newAxios.post("/reports/api/courses/fetchSelectedCourses.php", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    fetchAllCourses({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                const response = await newAxios.post("/reports/api/courses/fetchAllCourses.php", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    fetchBranch({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {

                const response = await newAxios.post("/reports/api/courses/fetchBranch.php", formData);

                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    fetchCompletedCount({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {

                const response = await newAxios.post("/reports/api/courses/fetchCompletedCount.php", formData);

                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    fetchCollegewiseStudents({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {

                const response = await newAxios.post("/reports/api/courses/fetchCollegewiseStudents.php", formData);

                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },


    downloadAllCompleted({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {

                const response = await newAxios.post("/reports/api/courses/test.php", formData);

                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    downloadFullReportAdmin({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {

                const response = await newAxios.post("/reports/api/courses/downloadCourseSelectionReport.php", formData);

                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    getStudentSocialLinkStatus({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                // const response = await newAxios.post("/reports/api/socials/fetchSocialLinkStatus.php", formData);
                const response = await newAxios.post("/reports/api/socials/fetchSocialLinkStatus.php", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    updateSocialLinkStatus({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                // const response = await newAxios.post("/reports/api/socials/fetchSocialLinkStatus.php", formData);
                const response = await newAxios.post("/reports/api/socials/updateSocialLinkStatus.php", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    generateSpocCertificate({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                // const response = await newAxios.post("/reports/api/socials/fetchSocialLinkStatus.php", formData);
                const response = await newAxios.post("/reports/api/spoc_certificates/generateCertificate.php", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    getSpocName({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                // const response = await newAxios.post("/reports/api/socials/fetchSocialLinkStatus.php", formData);
                const response = await newAxios.post("/reports/api/spoc_certificates/getSpocName.php", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    updateSpocName({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                // const response = await newAxios.post("/reports/api/socials/fetchSocialLinkStatus.php", formData);
                const response = await newAxios.post("/reports/api/spoc_certificates/updateSpocName.php", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    getSpocCertStatus({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                // const response = await newAxios.post("/reports/api/socials/fetchSocialLinkStatus.php", formData);
                const response = await newAxios.post("/reports/api/spoc_certificates/getSpocCertStatus.php", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },


    updateSpocCertStatus({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                // const response = await newAxios.post("/reports/api/socials/fetchSocialLinkStatus.php", formData);
                const response = await newAxios.post("/reports/api/spoc_certificates/updateSpocCertificate.php", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },


    

};
const studentPlacements: Module<any, any> = {
    state: () => ({
        placementData: {},
        placementStudentsList: [],
        placementStudentsCount: 0,
        placementStudentsTotalPages: 0,
        placementStudentsLimit: 20,
        placementStudentsPage: 0,
        placedCount: 0,
    }),
    mutations: {
        setPlacementData(state: any, data: any) {
            state.placementData = data;
        },
        setPlacementStudentsData(state, data) {
            state.placementStudentsList = data.data;
            state.studentsCount = data.count;
            state.placedCount = data.placed_count;
            state.aspirationCount = data.aspiration_count;
            state.placementStudentsTotalPages = Math.ceil(data.count / data.limit);
            state.placementStudentsLimit = data.limit;
            state.placementStudentsPage = data.page;
        },
    },
    actions,
    getters: {
        studentPlacement(state: any) {
            return state.placementData;
        },
        placementStudentsList(state: any) {
            return state.placementStudentsList;
        },
        placementStudentsCount(state: any) {
            return state.studentsCount;
        },
        placementStudentsTotalPages(state: any) {
            return state.placementStudentsTotalPages;
        },
        placementStudentsLimit(state: any) {
            return state.placementStudentsLimit;
        },
        placementStudentsPage(state: any) {
            return state.placementStudentsPage;
        },
        placedStudentsCount(state: any) {
            return state.placedCount;
        },
        aspirationFilledCount(state: any) {
            return state.aspirationCount;
        },
    },
};

export default studentPlacements;
